import { Container, Text, Footer, H1, H2 } from "../templates";
import ProjectList from "./ProjectList";
export default function Home() {
  return (
    <Container className="flex flex-col justify-center items-center">
      <H1>Real Fast Tech</H1>
      <Text>Specializing in fast and reliable software development and delivery</Text>
      <H2>Current Projects</H2>
      <ProjectList/>
      <Footer company="RealFastTech.Com" />
    </Container>
  );
}
