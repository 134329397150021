import { Card, Container } from "../templates";

export default function ProjectList(){
  const projects = [
    {
      title: "F5FruitShop",
      description: "An e-commerse to sell fruits online cooperating with offline stores in Vietnam.",
      imageUrl: "/images/f5fruitshop.jpg",
      url: "https://f5fruitshop.vn"
    },
    {
      title: "ThaoVu",
      description: "An educational project to inspire people to thrive for the happy life.",
      imageUrl: "/images/thaovu.png",
      url: "https://thaovu.org"
    },
    {
      title: "Dicverse",
      description: "Start your linguistic journey today and unlock a world of words with Dicverse.com.",
      imageUrl: "/images/dicverse.png",
      url: "https://dicverse.com"
    },
    {
      title: "Thao Academy",
      description: "Thao Academy offers STEMX education.",
      imageUrl: "/images/thaoacademy.png",
      url: "https://thaoacademy.com"
    }
  ]
  return (
    <Container>
      {
        projects.map((proj, index) => <Card {...proj} key={index}/>)
      }
    </Container>
  )
}