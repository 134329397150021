import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import NotFoundError from "./NotFoundError";
import F5fruitshop from "./F5fruitshop";

const router = createBrowserRouter([
  {
    path: "",
    element: <Home/>
  },
  {
    path: "/f5fruitshop",
    element: <F5fruitshop/>
  },
  {
    path: "*",
    element: <NotFoundError />
  }
]);

export default function Layout() {
  return <RouterProvider router={router} />;
}