import React from "react";
import Container from "./Container";

type CardProps = {
  title: string;
  description: string;
  imageUrl: string;
  url?: string;
};

const Card: React.FC<CardProps> = ({ title, description, imageUrl, url }) => {
  return (
    <Container className="max-w-sm w-full lg:max-w-full lg:flex border border-gray-400 bg-white m-2">
      <img
        className="lg:h-48 w-full lg:w-48 object-cover"
        src={imageUrl}
        alt={title}
      />

      <div className="mb-8">
        <div className="text-gray-900 font-bold text-xl mb-2">
          {title}
        </div>
        <p className="text-gray-700 text-base">
          {description}
        </p>
        {url && <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:text-blue-700 transition duration-300"
        >
          Learn More
        </a>}
      </div>
        
      </Container>
  );
};

export default Card;
